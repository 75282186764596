/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import { BrowserRouter } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { AutoLogin } from './core/auth'
import { GlobalContextProvider } from './core/globals'
import Routes from './ui/routes'

const App = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <GlobalContextProvider>
                <AutoLogin />

                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </GlobalContextProvider>
        </DndProvider>
    )
}

export default App

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import { login } from '../../core/auth'
import { useGlobals } from '../../core/globals'

import { Form, TextField, Submit } from '../forms'
import { LinkButton } from '../common'

const onValidate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = i18n.t('Please enter email address')
    }
    if (!values.password) {
        errors.password = i18n.t('Please enter password')
    }
    return errors
}

const LoginPage = () => {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate()
    const { setGlobals } = useGlobals()

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        setSubmitting(true)
        login(values, mutate, setGlobals).then(err => {
            if (err.error) {
                if (err.error === 1002) {
                    navigate('/register/confirm')
                } else {
                    setErrors({ password: i18n.t('Invalid password') })
                }
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <div className="w-50-md mx-auto">
            <Form initialValues={{ email: '', password: '' }} onValidate={onValidate} onSubmit={onSubmit}>
                <TextField name="email" type="text" placeholder={i18n.t('Email')} autoFocus />
                <TextField name="password" type="password" placeholder={i18n.t('Password')} />

                <div className="d-none d-md-block" style={{ height: 50 }} />

                <div className="text-end">
                    <LinkButton variant="link" size="sm" href="/reset-password">{ i18n.t('Forgot Password') }</LinkButton>
                </div>

                <div className="d-none d-md-block" style={{ height: 30 }} />

                <div className="row g-3">
                    <div className="col-6 p-2">
                        <Submit className="w-100" >{ i18n.t('Login') }</Submit>
                    </div>
                    <div className="col-6 p-2">
                        <LinkButton className="w-100" variant="outline-primary" href="/register">{ i18n.t('Sign Up') }</LinkButton>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default LoginPage

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import Collapse from 'react-bootstrap/Collapse'

import { segmentArray } from '../../core/utils'

import { IconButton } from '../common'

import PropertiesModal from './PropertiesModal'

const PropertiesWidget = ({ vessel }) => {
    const [ showProperties, setShowProperties ] = React.useState(false)
    const [ showEditDialog, setShowEditDialog ] = React.useState(false)

    const props = segmentArray(vessel.props, 4)

    return (
        <div className="vessel-widget p-3">
            <div className="d-flex flex-row align-items-center">
                <div className="prop value">{ vessel.name }</div>
                <div className="prop value">{ vessel.make }</div>
                <div className="prop value">{ vessel.model }</div>
                <div className="prop value">{ vessel.year }</div>
                <div className="flex-grow-1" />
                <IconButton icon="fa-solid fa-chevron-down" onClick={() => setShowProperties(!showProperties)}/>
            </div>

            <div className="d-flex flex-row">
                <div className="prop label">{ i18n.t('Boat Name') }</div>
                <div className="prop label">{ i18n.t('Make') }</div>
                <div className="prop label">{ i18n.t('Model') }</div>
                <div className="prop label">{ i18n.t('Year') }</div>
                <div className="flex-grow-1" />
            </div>

            <Collapse in={showProperties}>
                <div>
                    <div className="d-flex flex-row align-items-center">
                        <div className="prop value">{ vessel.length }</div>
                        <div className="prop value">{ vessel.hullid }</div>
                        <div className="flex-grow-1" />
                        <IconButton icon="fa-solid fa-pen-to-square" onClick={() => setShowEditDialog(true)}/>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="prop label">{ i18n.t('Length') }</div>
                        <div className="prop label">{ i18n.t('Hull ID') }</div>
                        <div className="flex-grow-1" />
                    </div>

                    <div className="divider my-4" />

                    { props.map((row, i) => (
                        <>
                            <div className="d-flex flex-row align-items-center" key={i}>
                                { row.map(({ value }, i) => (
                                    <div key={i} className="prop value">{ value }</div>
                                ))}
                            </div>
                            <div className="d-flex flex-row" key={i}>
                                { row.map(({ label }, i) => (
                                    <div key={i} className="prop label">{ label }</div>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            </Collapse>

            <PropertiesModal
                vessel={vessel}
                show={showEditDialog}
                toggle={() => setShowEditDialog(false)}
                />
        </div>
    )
}

export default PropertiesWidget

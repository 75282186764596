/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import React from 'react'

import { Spinner } from '.'

const LoadingPage = () => (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
        <Spinner />
    </div>
)

export default LoadingPage
/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import server from '../../core/server'

import { Form, TextField, Submit } from '../forms'
import { LinkButton, IconButton } from '../common'
import LoadingPage from '../common/LoadingPage'

const VendorsPage = () => {
    const { vesselId } = useParams()
    const { vessel, isLoading, isError } = server.useVessel(vesselId)

    if (isLoading) {
        return <LoadingPage />
    }
    if (isError) {
        return <Navigate to="/select" replace={true} />
    }

    return (
        <div className="m-2">
            Vendors for { vessel.name }
        </div>
    )
}

export default VendorsPage

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import React from 'react'

const GlobalContext = React.createContext({})

export class GlobalContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.setGlobals = (values) => this.setState(values)
        this.state = { setGlobals: this.setGlobals }
    }

    render() {
        return (
            <GlobalContext.Provider value={this.state}>
                { this.props.children }
            </GlobalContext.Provider>
        )
    }
}

export const useGlobals = () => React.useContext(GlobalContext)

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import serverapi from '../../core/server'

import PropsField from '../common/PropsField'
import { Form, TextField, Submit } from '../forms'

const validator = {
    name: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
    },
}

const PropertiesModal = ({ vessel, show, toggle }) => {
    const { mutate } = useSWRConfig()
    const [ initialValues, setInitialValues ] = React.useState({})

    React.useEffect(() => {
        const { name, make, model, year, length, hullid, props } = vessel
        setInitialValues({ name, make, model, year, length, hullid, props })
    }, [ vessel, setInitialValues ])

    const onSubmit = ({ values, setSubmitting, setErrors }) => {

        setSubmitting(true)
        serverapi.updateVessel(vessel.id, values, null, mutate).then(data => {
            toggle()
        }).catch(err => {
            if (err.error === 1000) {
                setErrors({ name: i18n.t('Name already in use') })
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <Modal show={show} onHide={toggle} size="lg">
            <Form initialValues={initialValues} validator={validator} onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{ i18n.t('Vessel Overview') }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                            <TextField name="name" type="text" label={i18n.t('Vessel Name')} grid autoFocus />
                            <TextField name="make" type="text" label={i18n.t('Make')} grid />
                            <TextField name="model" type="text" label={i18n.t('Model')} grid />
                            <TextField name="year" type="text" label={i18n.t('Year')} grid />
                            <TextField name="length" type="text" label={i18n.t('Length')} grid />
                            <TextField name="hullid" type="text" label={i18n.t('Hull ID')} grid />
                            <div className="divider my-4" />
                            <PropsField name="props" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={toggle}>{ i18n.t('Cancel') }</Button>
                    <Submit>{ i18n.t('Save') }</Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default PropertiesModal

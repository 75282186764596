/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import React from 'react'
import classNames from 'classnames'

import server from '../../core/server'

import { Clickable, ImageIcon } from '.'

const UserWidget = ({ className, style }) => {
    const { user, isLoading } = server.useProfile()

    const cls = classNames('d-flex flex-row align-items-center', className)
    return isLoading ? null : (
        <Clickable href="/profile" Element="div" className={cls} style={style}>
            <ImageIcon src={user.photo} size="sm" defaultIcon="fa-solid fa-user" />
            <div className="ms-2 text-secondary">{ [ user.firstname, user.lastname ].join(' ') }</div>
        </Clickable>
    )
}

export default UserWidget

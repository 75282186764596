/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import useSWR from 'swr'

import { getCookie } from './utils'

const apiPath = api => `/api/v1${api}`

const serverFetch = (api, ...args) => {
    console.log('GET', api)
    return fetch(apiPath(api), ...args).then(res => {
        if (res.status === 403) {
            return { error: 403, errdata: null } // don't retry 403
        }

        const data = res.json()
        if (data?.error) {
            console.warn('API error', api, data.error, data.errdata)
        }
        return data
    })
}

export const serverPost = (api, params={}, files, options={}) => {
    console.log('POST', api)

    let headers = { 'X-CSRFToken': getCookie('csrftoken') }
    let body

    if (files) {
        body = new FormData()
        body.append('jv', JSON.stringify(params))
        for (const key in files) {
            body.append(key, files[key])
        }
    } else {
        body = JSON.stringify(params)
        headers['content-type'] = 'application/json'
    }

    return fetch(apiPath(api), {
        method: 'POST',
        body,
        headers,
        credentials: 'same-origin',
        cache: 'no-cache',
        redirect: 'follow',
        ...options,
    }).then(resp => {
        if (resp.status === 403) {
            throw new Error('Permission denied')
        } else if (resp.status !== 200) {
            throw new Error('Bad response: '+resp.status)
        }
        return resp.json()
    }).then(data => {
        if (data.error) {
            throw data
        }
        return data
    })
}

const useApi = (dataKey, api, options={}) => {
    const { data, error } = useSWR(api, serverFetch, options)
    const errorData = error || data?.error
    return {
        [dataKey]: errorData ? null : data,
        isLoading: !error && !data,
        isError: !!errorData,
        error: errorData,
    }
}

const setKey = (key, mutate) => (data => { mutate(key, data); return data; })
const clearKey = (key, mutate) => (data => { mutate(key, null); return data; })

const server = {
    register: (params, mutate) => (
        serverPost('/user/register', params).then(setKey('/profile', mutate))
        // returns {}
    ),
    resendConfirmEmail: ({ email }) => (
        serverPost('/user/resend-email-confirmation', { email })
        // returns {}
    ),
    confirmEmail: ({ code }, mutate) => (
        serverPost('/user/confirm-email', { code }).then(setKey('/profile', mutate))
        // returns profile
    ),
    resetPassword: ({ email }) => (
        serverPost('/user/reset-password', { email })
        // returns {}
    ),
    resetPasswordConfirm: ({ code, password }) => (
        serverPost('/user/reset-confirm', { code, password })
        // returns {}
    ),
    login: ({ email, password }, mutate) => (
        serverPost('/user/login', { email, password }).then(setKey('/profile', mutate))
        // returns profile
    ),
    logout: (mutate) => (
        serverPost('/user/logout').then(clearKey('/profile', mutate))
        // returns {}
    ),
    getProfile: (mutate) => (
        serverPost('/profile').then(setKey('/profile', mutate))
        // returns profile
    ),

    useProfile: () => useApi('user', '/profile'),

    useVessels: () => useApi('vessels', '/vessel/list'),

    useVessel: (id) => useApi('vessel', `/vessel/get/${id}`),

    createVessel: (params, files, mutate) => (
        serverPost('/vessel/create', params, files).then(clearKey('/vessel/list', mutate))
    ),
    updateVessel: (id, params, files, mutate) => (
        serverPost(`/vessel/update/${id}`, params, files).then((data) => {
            mutate(`/vessel/get/${id}`, data)
            mutate('/vessel/list', null)
            return data
        })
    ),
    deleteVessel: (id, mutate) => (
        serverPost(`/vessel/delete`, { id }).then(clearKey('/vessel/list', mutate))
    ),
}

export default server

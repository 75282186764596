/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import serverapi from '../../core/server'

import { Form, TextField, Submit } from '../forms'
import { LinkButton } from '../common'
import { PasswordStrengthField } from '../common/PasswordStrength'

const initialValues = {
    code: '',
    password: '',
    password2: '',
}

const validator = {
    code: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
    },
    password: {
        password: true,
    },
    password2: {
        equality: { attribute: 'password', 'message': i18n.t('Passwords do not match') },
    }
}

const ResetPasswordConfirmPage = () => {
    const navigate = useNavigate()

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        setSubmitting(true)
        serverapi.resetPasswordConfirm(values).then(() => {
            navigate('/')
        }).catch(err => {
            if (err.error === 2) {
                setErrors({ code: i18n.t('Invalid reset code') })
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <>
            <p className="text-secondary">{ i18n.t('You should receive an email from VesseLogix with a password reset code.  Enter the reset code below and select a new password.') }</p>

            <Form initialValues={initialValues} validator={validator} onSubmit={onSubmit}>
                <TextField name="code" type="text" placeholder={i18n.t('Reset Code')} />
                <TextField name="password" type="password" placeholder={i18n.t('Password')} />
                <PasswordStrengthField className="mb-3" name="password" />
                <TextField name="password2" type="password" placeholder={i18n.t('Confirm Password')} />

                <Submit size="sm">{ i18n.t('Set New Password') }</Submit>
            </Form>

            <div style={{ height: 40 }} />
            <LinkButton variant="link" size="sm" href="/login">
                { i18n.t('Return to Sign In') }
            </LinkButton>
        </>
    )
}

export default ResetPasswordConfirmPage

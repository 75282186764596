/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import server from '../../core/server'

import { Form, TextField, Submit } from '../forms'
import { LinkButton, IconButton } from '../common'
import LoadingPage from '../common/LoadingPage'

const SecurityPage = () => {
    const { user, isLoading } = server.useProfile()

    return isLoading ? <LoadingPage /> : (
        <div className="m-2">
            Security for { user.firstname } { user.lastname }
        </div>
    )
}

export default SecurityPage

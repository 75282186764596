/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import { useNavigate, useParams } from 'react-router-dom'

import { confirmEmail } from '../../core/auth'
import { useGlobals } from '../../core/globals'

import { Spinner } from '../common'

const ConfirmEmailPage = () => {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate()
    const { setGlobals } = useGlobals()
    const { code } = useParams()
    const [ checking, setChecking ] = React.useState(false)
    const [ codeChecked, setCodeChecked ] = React.useState(null)

    React.useEffect(() => {
        if (code && !codeChecked && !checking) {
            setCodeChecked(code)
            setChecking(true)
            confirmEmail({ code }, mutate, setGlobals).catch(err => {
                console.log(err)
                navigate('/register/confirm')
            }).finally(() => {
                setChecking(false)
            })
        }
    }, [ code, checking, codeChecked, mutate, setGlobals, navigate ])

    return (
        <>
            <p className="text-secondary">{ i18n.t('Confirming email address...') }</p>
            <Spinner title="Confirming email address..." />
        </>
    )
}

export default ConfirmEmailPage

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'

import Button from 'react-bootstrap/Button'

import { useGlobals } from '../../core/globals'
import serverapi from '../../core/server'

import { Form, TextField, Submit } from '../forms'
import { LinkButton } from '../common'

const validator = {
    email: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
        email: { message: i18n.t('Please enter a valid email address') },
    },
}

const RegistrationConfirmPage = () => {
    const { auth } = useGlobals()
    const [ resent, setResent ] = React.useState(false)

    const initialValues = { email: auth?.user || '' }

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        setSubmitting(true)
        serverapi.resendConfirmEmail(values).then(() => {
            setResent(true)
            setTimeout(() => { setResent(false) }, 5000)
        }).catch(err => {
            if (err.error === 2) {
                setErrors({ email: i18n.t('Invalid or unknown email address') })
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <>
            <p className="text-secondary">{ i18n.t('You should receive an email from VesseLogix.  Please click the link in that email to confirm your email address.') }</p>

            <div className="d-none d-md-block" style={{ height: 80 }} />

            <p className="text-secondary fs-small">{ i18n.t('Enter your email below to resend the confirmation email') }</p>

            <Form initialValues={initialValues} validator={validator} onSubmit={onSubmit}>
                <TextField name="email" type="text" placeholder={i18n.t('Email')} />
                { resent ? (
                    <Button size="sm" variant="success" disabled>{ i18n.t('Sent!') }</Button>
                ) : (
                    <Submit size="sm">{ i18n.t('Send') }</Submit>
                )}
            </Form>

            <div className="d-none d-md-block" style={{ height: 80 }} />
            <p className="text-secondary fs-small">{ i18n.t('If you have already confirmed your email address, click here to sign in') }</p>
            <LinkButton className="" variant="outline-primary" href="/login">{ i18n.t('Sign In') }</LinkButton>
        </>
    )
}

export default RegistrationConfirmPage
/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { useField } from '../forms'

const PasswordStrength = ({ value, className, style }) => {
    const [ zxcvbn, setZxcvbn ] = React.useState(null)
    const [ score, setScore ] = React.useState(null)

    React.useEffect(() => {
        import('zxcvbn').then(zxcvbn => setZxcvbn(zxcvbn))
    }, [ setZxcvbn ])

    React.useEffect(() => {
        if (zxcvbn && value?.length > 0) {
            setScore(zxcvbn.default(value).score)
        } else {
            setScore(null)
        }

    }, [ value, zxcvbn, setScore ])

    let now, label, variant
    if (score === null) {
        now = 0
        label = ''
        variant = 'danger'
    } else if (value.length < 8) {
        now = 0
        label = i18n.t('Password is too short')
        variant = 'danger'
    } else {
        now = 25 * score
        label = {
            0: i18n.t('Weak Password'),
            1: i18n.t('Weak Password'),
            2: i18n.t('Moderate Password'),
            3: i18n.t('Good Password'),
            4: i18n.t('Strong Password'),
        }[score]
        variant = {
            0: 'danger',
            1: 'danger',
            2: 'warning',
            3: 'success',
            4: 'success',
        }[score]
    }

    return score === null ? null : (
        <div className={className} style={style}>
            <ProgressBar now={now} variant={variant} />
            <span className="PasswordStrengthLabel">{ label }</span>
        </div>
    )
}

export default PasswordStrength

export const PasswordStrengthField = ({ name, ...props }) => {
    const { value } = useField(name)
    return <PasswordStrength value={value} { ...props }/>
}

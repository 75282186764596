/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import React from 'react'
import { useSWRConfig } from 'swr'

import { useGlobals } from './globals'
import serverapi from './server'

export const confirmEmail = (params, mutate, setGlobals) => {
    return serverapi.confirmEmail(params, mutate).then(profile => {
        setGlobals({ auth: { user: profile.email, loggedIn: true }})
        localStorage.setItem('loggedIn', profile.email)
        return profile
    })
}

export const login = (params, mutate, setGlobals) => {
    return serverapi.login(params, mutate).then(profile => {
        setGlobals({ auth: { user: profile.email, loggedIn: true }})
        localStorage.setItem('loggedIn', profile.email)
        return profile
    }).catch(err => {
        const auth = { loggedIn: false }
        if (err.error === 1002) {
            Object.assign(auth, { user: params.email, needConfirmEmail: true })
        }
        setGlobals({ auth })
        localStorage.removeItem('loggedIn')
        return err
    })
}

export const logout = (mutate, setGlobals) => {
    return serverapi.logout(mutate).then(() => {
        setGlobals({ auth: {} })
        localStorage.removeItem('loggedIn')
    })
}

export const AutoLogin = () => {
    const { mutate } = useSWRConfig()
    const { setGlobals } = useGlobals()

    React.useEffect(() => {
        if (localStorage.getItem('loggedIn')) {
            serverapi.getProfile(mutate).then(profile => {
                setGlobals({ auth: { user: profile.email, loggedIn: true }})
                localStorage.setItem('loggedIn', profile.email)
            }).catch(err => {
                console.log(err)
                setGlobals({ auth: { loggedIn: false }})
            })
        } else {
            setGlobals({ auth: { loggedIn: false }})
        }
    }, [ mutate, setGlobals ])

    return null
}

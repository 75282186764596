/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import server from '../../core/server'

import { ImageIcon } from '../common'
import LoadingPage from '../common/LoadingPage'

import EquipmentWidget from './EquipmentWidget'
import LocationsWidget from './LocationsWidget'
import PropertiesWidget from './PropertiesWidget'
import TasksWidget from './TasksWidget'

const Separator = () => <div className="my-3" />

const VesselDashboardPage = () => {
    const { vesselId } = useParams()
    const { vessel, isLoading, isError } = server.useVessel(vesselId)

    if (isLoading) {
        return <LoadingPage />
    }
    if (isError) {
        return <Navigate to="/select" replace={true} />
    }

    return (
        <div className="container p-4">
            <div className="row g-3">
                <div className="col-md-5">
                    <ImageIcon src={vessel.photo} size="auto" fmt={[480, 240]}/>
                    <Separator />
                    <PropertiesWidget vessel={vessel} />
                    <Separator />
                    <EquipmentWidget vessel={vessel} />
                </div>
                <div className="col-md-7">
                    <LocationsWidget vessel={vessel} />
                    <Separator />
                    <TasksWidget vessel={vessel} />
                </div>
            </div>
        </div>
    )
}

export default VesselDashboardPage

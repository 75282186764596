/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import React from 'react'
import { useNavigate } from 'react-router-dom'

import serverapi from '../../core/server'

import { Spinner, IconButton, PlusButton, ImageIcon } from '../common'

import DeleteVesselModal from './DeleteVesselModal'

const VesselTile = ({ vessel, onDelete }) => {
    const navigate = useNavigate()

    const deleteVessel = (ev) => {
        ev.stopPropagation()
        onDelete(vessel)
    }

    return (
        <div className="vessel-tile" onClick={() => navigate(`/vessel/${vessel.id}`)}>
            <ImageIcon src={vessel.photo} />
            <div className="vessel-tile-text flex-grow-1">
                <h3>{ vessel.name }</h3>
                <p>{ vessel.year }</p>
                <p>{ vessel.model }</p>
            </div>
            <div className="vessel-tile-buttons">
                <IconButton icon="fa-regular fa-trash-can" onClick={deleteVessel} />
            </div>
        </div>
    )
}

const VesselGrid = () => {
    const navigate = useNavigate()
    const [ deleteVessel, setDeleteVessel ] = React.useState(null)
    const { vessels, isLoading } = serverapi.useVessels()

    if (!isLoading && vessels.data?.length === 0) {
        navigate('new')
    }

    return isLoading ? <Spinner /> : (
        <div className="vessel-grid row g-3">
            { vessels.data?.map((vessel) => (
                <div className="col-md-3" key={'vessel-tile-'+vessel.id}>
                    <VesselTile vessel={vessel} onDelete={setDeleteVessel}/>
                </div>
            ))}

            <DeleteVesselModal vessel={deleteVessel} toggle={() => setDeleteVessel(null)} />
        </div>
    )
}

const VesselSelectPage = () => {

    return (
        <>
            <VesselGrid />

            <div className="my-5">
                <PlusButton href="new" />
            </div>
        </>
    )
}

export default VesselSelectPage

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { useGlobals } from '../core/globals'

import LoadingPage from './common/LoadingPage'

import FilesPage from './files/FilesPage'

import BillingPage from './profile/BillingPage'
import ProfilePage from './profile/ProfilePage'
import SecurityPage from './profile/SecurityPage'
import SupportPage from './profile/SupportPage'

import LoginPage from './public/LoginPage'
import ConfirmEmailPage from './public/ConfirmEmailPage'
import RegistrationPage from './public/RegistrationPage'
import RegistrationConfirmPage from './public/RegistrationConfirmPage'
import ResetPasswordPage from './public/ResetPasswordPage'
import ResetPasswordConfirmPage from './public/ResetPasswordConfirmPage'

import VendorsPage from './vendors/VendorsPage'

import VesselCreatePage from './vessel/VesselCreatePage'
import VesselDashboardPage from './vessel/VesselDashboardPage'
import VesselSelectPage from './vessel/VesselSelectPage'

import { HomeLayout, SelectionLayout, PublicLayout } from './common/layouts'

const PublicRoutes = ({ auth }) => (
    <Routes>
        <Route path="/" element={<PublicLayout />}>
            <Route path="login" element={<LoginPage/>} />
            <Route path="register" element={<RegistrationPage/>} />
            <Route path="register/confirm" element={<RegistrationConfirmPage/>} />
            <Route path="confirm-email/:code" element={<ConfirmEmailPage/>} />
            <Route path="reset-password" element={<ResetPasswordPage/>} />
            <Route path="reset-password/confirm" element={<ResetPasswordConfirmPage/>} />

            <Route index={true} element={<LoginPage/>} />
            <Route path="*" element={auth.needConfirmEmail ? <ConfirmEmailPage /> : <LoginPage />} />
        </Route>
    </Routes>
)

const MainRoutes = () => (
    <Routes>
        <Route path="/select" element={<SelectionLayout />}>
            <Route index={true} element={<VesselSelectPage/>} />
            <Route path="new" element={<VesselCreatePage/>} />

            <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Route>
        <Route path="/" element={<HomeLayout />}>
            <Route path="vessel/:vesselId" element={<VesselDashboardPage/>} />
            <Route path="files/:vesselId" element={<FilesPage/>} />
            <Route path="vendors/:vesselId" element={<VendorsPage/>} />

            <Route path="profile" element={<ProfilePage />} />
            <Route path="billing" element={<BillingPage />} />
            <Route path="security" element={<SecurityPage />} />
            <Route path="support" element={<SupportPage />} />

            <Route index={true} element={<Navigate to="select" replace={true} />} />
            <Route path="*" element={<Navigate to="select" replace={true} />} />
        </Route>
        <Route path="*" element={<Navigate to="/select" replace={true} />} />
    </Routes>
)

const AppRoutes = () => {
    const { auth } = useGlobals()

    if (auth === undefined) {
        return <LoadingPage />
    }

    return auth.loggedIn ? <MainRoutes /> : <PublicRoutes auth={auth} />
}

export default AppRoutes

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import classNames from 'classnames'

import server from '../../core/server'

import { Form, TextField, Submit } from '../forms'
import { LinkButton, IconButton, ImageIcon } from '../common'

const LocationsWidget = ({ vessel }) => {
    return (
        <div className="vessel-widget p-3">
        Locations of { vessel.name }
        </div>
    )
}

export default LocationsWidget

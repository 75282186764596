/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import classNames from 'classnames'
import { useDrop, useDrag } from 'react-dnd'

import { useField } from '../forms'

import { IconButton } from '.'

const PropItemType = 'prop-row'

const PropRow = ({
    index,
    prop,
    actions,
    submitting,
    containerClass,
    gridSplit,
}) => {
    const ref = React.useRef(null)

    const [{ isDragging }, drag, preview] = useDrag({
		type: PropItemType,
		item: () => ({ index, ogIndex: index }),
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                actions.moveRow(item.index, item.ogIndex)
            }
        },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

    const [ , drop] = useDrop({
		accept: PropItemType,
		hover(item, monitor) {
            if (index === item.index) {
                return
            }

			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			const clientOffset = monitor.getClientOffset()
			const hoverClientY = clientOffset.y - hoverBoundingRect.top

			if (item.index < index && hoverClientY < hoverMiddleY) {
				return
			}
			if (item.index > index && hoverClientY > hoverMiddleY) {
				return
			}

            actions.moveRow(item.index, index)
            item.index = index
		},
	})

    drop(preview(ref))
    return (
        <div
            ref={ref}
            className={classNames('row align-items-center', containerClass)}
            style={{ opacity: isDragging ? 0 : 1 }}
            >
            <div className={`col-${gridSplit}`}>
                <input
                    className="form-control"
                    type="text"
                    value={prop.label}
                    placeholder={i18n.t('Label')}
                    onChange={(ev) => actions.setRow(index, ev.target.value, prop.value)}
                    disabled={submitting}
                    />
            </div>
            <div className={`col-${11 - gridSplit}`}>
                <input
                    className="form-control"
                    type="text"
                    value={prop.value}
                    placeholder={i18n.t('Value')}
                    onChange={(ev) => actions.setRow(index, prop.label, ev.target.value)}
                    disabled={submitting}
                    />
            </div>
            <div className="col-1">
                <i ref={drag} className="fa-solid fa-grip-lines me-2 cursor-move" />
                <IconButton icon="fa-solid fa-xmark" onClick={() => actions.deleteRow(index)}/>
            </div>
        </div>
    )
}

let propKey = 1

const PropsField = ({ name, containerClass='mb-3', gridSplit=6 }) => {
    const { value, setValue, submitting } = useField(name)
    const [ trigger, setTrigger ] = React.useState(0)

    React.useEffect(() => {
        if (value?.find(prop => !prop.key)) {
            const next = value.slice()
            next.forEach(prop => {
                if (!prop.key) {
                    prop.key = propKey++
                }
            })
            setValue(next)
            setTrigger(trigger+1) // force render
        }
    }, [ value, setValue, trigger, setTrigger ])

    const addRow = React.useCallback(() => {
        const next = value.slice()
        next.push({ label: '', value: '', key: propKey++ })
        setValue(next)
    }, [ value, setValue ])

    const setRow = React.useCallback((i, label, val) => {
        const next = value.slice()
        next[i] = { key: value[i].key, label, value: val }
        setValue(next)
    }, [ value, setValue ])

    const moveRow = React.useCallback((from, to) => {
        const next = value.slice()
        const t = next[to]
        next[to] = next[from]
        next[from] = t
        setValue(next)
    }, [ value, setValue ])

    const deleteRow = React.useCallback((i) => {
        const next = value.slice()
        next.splice(i, 1)
        setValue(next)
    }, [ value, setValue ])

    const [, drop] = useDrop(() => ({ accept: PropItemType }))

    if (value?.find(prop => !prop.key)) {
        return null
    }

    return (
        <div ref={drop}>
            { (value || []).map((prop, i) => (
                <PropRow
                    key={prop.key}
                    index={i}
                    prop={prop}
                    actions={{ setRow, moveRow, deleteRow }}
                    submitting={submitting}
                    containerClass={containerClass}
                    gridSplit={gridSplit}
                    />
            ))}
            <div className={classNames('row', containerClass)}>
                <div className="col-12 text-center">
                    <IconButton icon="fa-solid fa-plus" onClick={addRow}/>
                </div>
            </div>
        </div>
    )
}

export default PropsField

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'

import { useNavigate } from 'react-router-dom'

import { useGlobals } from '../../core/globals'
import serverapi from '../../core/server'

import { Form, TextField, Submit } from '../forms'
import { LinkButton } from '../common'

const validator = {
    email: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
        email: { message: i18n.t('Please enter a valid email address') },
    },
}

const ResetPasswordPage = () => {
    const { auth } = useGlobals()
    const navigate = useNavigate()

    const initialValues = { email: auth?.user || '' }

    const onSubmit = ({ values, setSubmitting }) => {
        setSubmitting(true)
        serverapi.resetPassword(values).then(() => {
            navigate('/reset-password/confirm')
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <>
            <p className="text-secondary">{ i18n.t('Enter your email below to reset your password') }</p>

            <Form initialValues={initialValues} validator={validator} onSubmit={onSubmit}>
                <TextField name="email" type="text" placeholder={i18n.t('Email')} />
                <Submit size="sm">{ i18n.t('Reset Password') }</Submit>
            </Form>

            <div style={{ height: 40 }} />
            <LinkButton variant="link" size="sm" href="/login">
                { i18n.t('Return to Sign In') }
            </LinkButton>
        </>
    )
}

export default ResetPasswordPage

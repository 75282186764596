/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import { useNavigate } from 'react-router-dom'

import { useGlobals } from '../../core/globals'
import serverapi from '../../core/server'

import { Form, TextField, CheckField, Submit } from '../forms'
import { Clickable, LinkButton } from '../common'
import { PasswordStrengthField } from '../common/PasswordStrength'

const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    password2: '',
    agreeToc: false,
}

const validator = {
    firstname: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
    },
    lastname: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
    },
    email: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
        email: { message: i18n.t('Please enter a valid email address') },
    },
    phone: {
        phone: { optional: true },
    },
    password: {
        password: true,
    },
    password2: {
        equality: { attribute: 'password', 'message': i18n.t('Passwords do not match') },
    }
}

const RegistrationPage = () => {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate()
    const { setGlobals } = useGlobals()

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        const { password2, agreeToc, ...params } = values

        if (!agreeToc) {
            setErrors({ agreeToc: i18n.t('Must agree to Terms and Conditions') })
            return
        }

        setSubmitting(true)
        serverapi.register(params, mutate).then(() => {
            navigate('/register/confirm')
        }).catch(err => {
            console.log(err)
            if (err.error === 1000) {
                setErrors({ email: i18n.t('Email is already in use') })
            } else if (err.error === 1001) {
                setErrors({ password: i18n.t('Please enter a stronger password') })
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    const TCLabel = (
        <span className="fs-small">
            { i18n.t('I agree with the') }&nbsp;
            <Clickable onClick={() => setGlobals({ showTC: true })}>{ i18n.t('terms and conditions') }</Clickable>
        </span>
    )

    return (
        <>
            <p className="text-secondary fs-small">{ i18n.t('Please complete to create your account') }</p>

            <Form initialValues={initialValues} validator={validator} onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <TextField name="firstname" type="text" placeholder={i18n.t('First Name')} autoFocus />
                    </div>
                    <div className="col-md-6">
                        <TextField name="lastname" type="text" placeholder={i18n.t('Last Name')} />
                    </div>
                </div>
                <TextField name="email" type="text" placeholder={i18n.t('Email')} />
                <TextField name="phone" type="text" placeholder={i18n.t('Phone')} />
                <TextField name="password" type="password" placeholder={i18n.t('Password')} />
                <PasswordStrengthField className="mb-3" name="password" />
                <TextField name="password2" type="password" placeholder={i18n.t('Confirm Password')} />
                <CheckField name="agreeToc" label={TCLabel} />

                <div className="my-5">
                    <Submit>{ i18n.t('Sign Up') }</Submit>
                </div>

                <LinkButton variant="link" size="sm" href="/login">
                    { i18n.t('Already have an account?  Sign in') }
                </LinkButton>
            </Form>
        </>
    )
}

export default RegistrationPage

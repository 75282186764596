/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { logout } from '../../core/auth'
import { useGlobals } from '../../core/globals'

import { Clickable } from '.'
import { HomeNav, MobileTopNav } from './HomeNav'

export const PublicLayout = () => {
    const { showTC, showPrivacy, setGlobals } = useGlobals()

    const hideTC = () => setGlobals({ showTC: false })
    const hidePrivacy = () => setGlobals({ showPrivacy: false })

    return (
        <div className="public-layout row g-0 vh-100">
            <div className="col-md-6">
                <div className="boat-background" />
            </div>
            <div className="col-md-6">
                <div className="container">
                    <div className="w-75-md p-2 text-center">
                        <img className="logo" src="../../img/logo509.gif" alt="VesseLogix"/>
                        <Outlet />
                    </div>

                    <div className="position-absolute bottom-0 p-3 fs-small">
                        <Clickable onClick={() => setGlobals({ showTC: true })}>
                            { i18n.t('Terms and Conditions') }
                        </Clickable>
                        &nbsp;|&nbsp;
                        <Clickable onClick={() => setGlobals({ showPrivacy: true })}>
                            { i18n.t('Privacy Policy') }
                        </Clickable>
                    </div>

                </div>
            </div>

            <Modal show={!!showTC} onHide={hideTC}>
                <Modal.Header closeButton>
                    <Modal.Title>{ i18n.t('Terms and Conditions') }</Modal.Title>
                </Modal.Header>
                <Modal.Body>{ i18n.t('You agree to stuff') }</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideTC}>{ i18n.t('OK') }</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={!!showPrivacy} onHide={hidePrivacy}>
                <Modal.Header closeButton>
                    <Modal.Title>{ i18n.t('Privacy Policy') }</Modal.Title>
                </Modal.Header>
                <Modal.Body>{ i18n.t('You agree to stuff') }</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hidePrivacy}>{ i18n.t('OK') }</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export const SelectionLayout = () => {
    const { mutate } = useSWRConfig()
    const { setGlobals } = useGlobals()

    return (
        <div className="selection-layout min-vh-100">
            <img className="logo" src="../../img/logo509.gif" alt="VesseLogix"/>

            <Outlet />

            <div className="flex-grow-1" />

            <div className="p-4">
                <Clickable className="text-reset text-decoration-none" onClick={() => logout(mutate, setGlobals)}>
                    { i18n.t('Logout') }
                </Clickable>
                &nbsp;|&nbsp;
                <Link className="text-reset text-decoration-none" to="/">
                    { i18n.t('Support') } <i className="fa-regular fa-comment-dots" />
                </Link>
            </div>
        </div>
    )
}

export const HomeLayout = () => {
    const [ navVisible, setNavVisible ] = React.useState(false)

    const toggleNav = () => setNavVisible(!navVisible)

    return (
        <div className="home-layout vh-100 d-flex flex-column">
            <MobileTopNav visible={navVisible} toggle={toggleNav} />
            <div className="d-flex flex-row flex-grow-1">
                <HomeNav visible={navVisible} />
                <div className="home-content flex-grow-1">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { Form, Submit } from '../forms'
import serverapi from '../../core/server'

const DeleteVesselModal = ({ vessel, toggle }) => {
    const { mutate } = useSWRConfig()

    const onDelete = ({ setSubmitting }) => {
        setSubmitting(true)
        serverapi.deleteVessel(vessel.id, mutate).finally(() => {
            setSubmitting(false)
            toggle()
        })
    }

    return (
        <Modal show={!!vessel} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>{ i18n.t('Please Confirm') }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { i18n.t('You are about to delete the vessel') }:&nbsp;
                { vessel?.name }.
                <br/><br/>
                { i18n.t('This action is permanent.  Do you want to proceed?') }
            </Modal.Body>
            <Modal.Footer>
                <Form initialValues={{}} onSubmit={onDelete}>
                    <Button variant="outline-secondary" onClick={toggle}>{ i18n.t('Cancel') }</Button>
                    <Submit variant="danger">{ i18n.t('Delete') }</Submit>
                </Form>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteVesselModal

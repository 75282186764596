/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

export function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    if (match) {
        return match[2]
    }
}

export const fileUrl = (value) => {
    let url
    if (typeof value === 'string' && value.length > 0) {
        url = `/api/v1/file/${value}`
    } else if (value) {
        url = value.preview
    }
    return url
}

export const segmentArray = (a, n) => {
    const r = []

    let b = []
    for (let i=0; i<a.length; ++i) {
        b.push(a[i])

        if (b.length >= n) {
            r.push(b)
            b = []
        }
    }

    if (b.length > 0) {
        r.push(b)
    }

    return r
}

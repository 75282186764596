/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'

import { Clickable, IconButton, SearchBox } from '../common'
import UserWidget from '../common/UserWidget'

export const MobileTopNav = ({ visible, toggle }) => (
    <div className={classNames('top-nav', {'bg-grey': visible})}>
        <IconButton icon="fa-solid fa-bars" onClick={toggle} />
        <img className="logo" src="../../img/logo255.gif" alt="VesseLogix"/>
    </div>
)

const NavLink = ({ href, icon, label }) => (
    <Clickable Element="div" className="navlink mx-4 my-2 text-secondary" href={href}>
        <i className={icon} />&nbsp;&nbsp;{ label }
    </Clickable>
)

export const HomeNav = ({ visible }) => {
    const { vesselId } = useParams()
    const [ linkVessel, setLinkVessel ] = React.useState(null)

    React.useEffect(() => {
        if (vesselId) {
            localStorage.setItem('currentVessel', vesselId)
            setLinkVessel(vesselId)
        } else {
            setLinkVessel(localStorage.getItem('currentVessel'))
        }
    }, [ vesselId, setLinkVessel ])

    return (
        <div className={classNames('home-nav d-flex flex-column pt-3', {'visible': !visible})}>
            <img
                className="logo d-none d-md-block align-self-center my-5"
                width={180} src="../../img/logo255.gif" alt="VesseLogix"/>
            <UserWidget className="mx-3"/>

            <div className="divider my-4" />

            { linkVessel && (
                <NavLink href={`/vessel/${linkVessel}`} icon="fa-solid fa-cubes" label={i18n.t('Dashboard')} />
            )}
            <NavLink href="/select" icon="fa-solid fa-ship" label={i18n.t('Vessels')} />
            <NavLink href="/profile" icon="fa-solid fa-user" label={i18n.t('Profile')} />
            <NavLink href="/billing" icon="fa-solid fa-credit-card" label={i18n.t('Billing')} />
            <NavLink href="/security" icon="fa-solid fa-shield" label={i18n.t('Security')} />

            <div className="divider my-4" />

            <SearchBox className="mx-3 mb-4" onChange={() => null} />

            { linkVessel && (
                <NavLink href={`/vendors/${linkVessel}`} icon="fa-solid fa-users" label={i18n.t('Vendors')} />
            )}
            { linkVessel && (
                <NavLink href={`/files/${linkVessel}`} icon="fa-solid fa-file" label={i18n.t('Files')} />
            )}

            <div className="divider my-4" />

            <div className="mx-3 flex-grow-1">Favorites</div>

            <div className="divider my-4" />

            <NavLink href="/support" icon="fa-regular fa-comment-dots" label={i18n.t('Support')} />
        </div>
    )
}

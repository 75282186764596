/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import Button from 'react-bootstrap/Button'
import BootstrapSpinner from 'react-bootstrap/Spinner'

import { fileUrl } from '../../core/utils'

export const Spinner = ({ title='Loading...' }) => (
    <BootstrapSpinner animation="border" role="status">
        <span className="visually-hidden">{ title }</span>
    </BootstrapSpinner>
)

export const Clickable = ({ href, onClick, Element='a', className, children, ...props }) => {
    const navigate = useNavigate()

    const click = (ev) => {
        if (onClick) {
            onClick(ev)
        } else if (href) {
            navigate(href)
        }
        ev.preventDefault()
    }

    const cls = classNames('pointer', className)

    return (
        <Element href={href || '#'} onClick={click} className={cls} { ...props }>
            { children }
        </Element>
    )
}

export const LinkButton = ({ children, ...props }) => (
    <Clickable Element={Button} { ...props}>
        { children }
    </Clickable>
)

export const IconButton = ({ icon, label, ...props }) => (
    <Clickable className="icon-btn" { ...props}>
        <i className={icon} />
        { label && <div className="ms-3">{ label }</div>}
    </Clickable>
)

export const PlusButton = ({ variant='primary', onClick, href, ...props }) => {
    const navigate = useNavigate()

    if (href && !onClick) {
        onClick = () => navigate(href)
    }

    return (
        <Button className="plus-button" variant={variant} onClick={onClick} { ...props}>
            <i className="fa-solid fa-plus" />
        </Button>
    )
}

export const ImageIcon = ({ src, size='md', fmt, alt='image', defaultIcon='fa-solid fa-image' }) => {

    if (!fmt && size instanceof Array) {
        fmt = size
    }

    src = fileUrl(src)
    if (src && fmt) {
        src += `?fmt=${fmt[0]}x${fmt[1]}`
    }

    let style = {}, className = 'image-icon shadow'
    if (src) {
        style.backgroundImage = `url(${src})`
    }
    if (size instanceof Array) {
        style.width = size[0]
        style.height = size[1]
    } else if (size === 'auto') {
        className += ' w-100'
    } else {
        className += ' ' + size
    }

    if (src && size === 'auto') {
        return <img src={src} alt={alt} className={className} height="auto"/>
    }

    return (
        <div className={className} style={style}>
            { !src && <i className={defaultIcon} /> }
        </div>
    )
}

export const SearchBox = ({ className, onChange, ...props }) => (
    <div className={classNames('searchbox', className)} { ...props}>
        <i className="fa-solid fa-magnifying-glass" />
        <input type="text" placeholder={i18n.t('Search...')} />
    </div>
)

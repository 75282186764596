/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import serverapi from '../../core/server'

import { Form, TextField, ImageField, Submit } from '../forms'
import { LinkButton } from '../common'

const initialValues = {
    //photo: null,
    name: '',
    make: '',
    model: '',
    year: '',
}

const validator = {
    name: {
        presence: { allowEmpty: false, message: i18n.t('Required') },
    },
}

const VesselCreatePage = () => {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate()

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        let files = null
        if (values.photo) {
            files = { photo: values.photo }
            delete values.photo
        }

        setSubmitting(true)
        serverapi.createVessel(values, files, mutate).then(data => {
            if (data.id) {
                navigate(`/vessel/${data.id}`, { replace: true})
            }
        }).catch(err => {
            if (err.error === 1000) {
                setErrors({ name: i18n.t('Name already in use') })
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <>
            <div className="d-none d-md-block" style={{ height: 40 }} />

            <Form initialValues={initialValues} validator={validator} onSubmit={onSubmit}>
                <ImageField name="photo" width={200} height={200}/>
                <TextField name="name" type="text" placeholder={i18n.t('Vessel Name')} autoFocus />
                <TextField name="make" type="text" placeholder={i18n.t('Make')} />
                <TextField name="model" type="text" placeholder={i18n.t('Model')} />
                <TextField name="year" type="text" placeholder={i18n.t('Year')} />

                <div className="d-none d-md-block" style={{ height: 40 }} />

                <div className="row g-3">
                    <div className="col-6 p-2">
                        <LinkButton variant="outline-primary" href="..">{ i18n.t('Cancel') }</LinkButton>
                    </div>
                    <div className="col-6 p-2">
                        <Submit>{ i18n.t('Create') }</Submit>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default VesselCreatePage

/*-----------------------------------------------------------------------------
 *
 *  Copyright (C) Buttermilk Cake Co, LLC and its affiliates
 *
 *                   ALL RIGHTS RESERVED
 *
 *-----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useDropzone } from 'react-dropzone'
import validate from 'validate.js'
import classNames from 'classnames'

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { fileUrl } from '../core/utils'

validate.validators.password = (value, options) => (
    new validate.Promise((resolve, reject) => {
        import('zxcvbn').then(zxcvbn => {
            if (value.length < 8) {
                resolve(i18n.t('Password is too short'))
            } else if (zxcvbn.default(value).score >= 2) {
                resolve()
            } else {
                resolve(i18n.t('Please select a stronger password'))
            }
        })
    })
)

const phoneRe = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
validate.validators.phone = (value, options) => {
    if (options?.optional && !value) {
        return null
    }
    if (validate.isString(value) && value.match(phoneRe)) {
        return null
    }

    return options?.message || i18n.t('Please enter a valid phone number')
}

const FormContext = React.createContext({})

export const Form = ({ initialValues, onValidate, validator, onSubmit, children }) => {
    const [ values, setValues ] = React.useState(initialValues)
    const [ errors, setErrors ] = React.useState({})
    const [ submitting, setSubmitting ] = React.useState(false)

    React.useEffect(() => {
        setValues(initialValues)
    }, [ initialValues, setValues ])

    const form = {
        values,
        setValues,
        errors,
        setErrors,
        submitting,
        setSubmitting,
        onValidate,
        validator,
        onSubmit
    }

    return (
        <FormContext.Provider value={form}>
            { children }
        </FormContext.Provider>
    )
}

const submitForm = async ({ values, setErrors, setSubmitting, onValidate, validator, onSubmit }) => {
    let errors = {}

    if (onValidate) {
        errors = onValidate(values)
    } else if (validator) {
        errors = await validate.async(values, validator, { fullMessages: false }).then(
            () => ({})
        ).catch(errors => {
            for (const field in errors) {
                errors[field] = errors[field][0]
            }
            return errors
        })
    }

    setErrors(errors)
    if (Object.keys(errors).length === 0) {
        onSubmit({ values, setErrors, setSubmitting })
    }
}

export const useForm = () => React.useContext(FormContext)

export const useField = (name) => {
    const { values, setValues, errors, setErrors, submitting } = useForm()
    return {
        value: values[name],
        setValue: v => setValues({ ...values, [name]: v }),
        error: errors[name],
        setError: e => setErrors({ ...errors, [name]: e }),
        submitting
    }
}

export const Input = ({ name, type='text', className='form-control', ...props }) => {
    const { value, error, setValue, submitting } = useField(name)
    return (
        <input
            className={className + (error ? ' is-invalid' : '')}
            type={type}
            name={name}
            value={value}
            onChange={(ev) => {
                if (type === 'checkbox') {
                    setValue(ev.target.checked)
                } else {
                    setValue(ev.target.value)
                }
            }}
            disabled={submitting}
            { ...props}
            />
    )
}

export const Field = ({
    name,
    label,
    labelClass='form-label',
    labelFirst=true,
    helpText,
    containerClass='mb-3',
    containerStyle,
    grid,
    gridSplit=6,
    children,
    ...props
}) => {
    const { error } = useField(name)
    const Label = () => !!label && <label className={labelClass}>{ label }</label>

    return grid ? (
        <div className={classNames('row', containerClass)}>
            <label className={`col-md-${gridSplit} col-form-label`}>{ label }</label>
            <div className={`col-md-${12 - gridSplit}`}>
                { children(props) }
                { error && <div className="invalid-feedback">{ error }</div> }
                { helpText && <div className="form-text">{ helpText }</div> }
            </div>
        </div>
    ) : (
        <div className={containerClass} style={containerStyle}>
            { labelFirst && <Label /> }
            { children(props) }
            { !labelFirst && <Label /> }
            { error && <div className="invalid-feedback">{ error }</div> }
            { helpText && <div className="form-text">{ helpText }</div> }
        </div>
    )
}

export const TextField = ({ name, ...props }) => (
    <Field name={name} { ...props }>
        {(props) => (
            <Input className="form-control" name={name} { ...props }/>
        )}
    </Field>
)

export const CheckField = ({ name, ...props }) => (
    <Field
        name={name}
        containerClass="form-check mb-3 text-start"
        labelClass="form-check-label"
        labelFirst={false}
        { ...props }
        >
        {(props) => (
            <Input className="form-check-input" type="checkbox" name={name} { ...props } />
        )}
    </Field>
)

export const ChoiceField = ({ name, choices, ...props }) => {
    const { value, setValue } = useField(name)
    return (
        <Field name={name} { ...props }>
            {() => (
                <select className="form-select" onChange={setValue}>
                    { choices?.map(([ v, label ]) => (
                        <option key={'opt-'+v} value={v} selected={v === value}>{ label }</option>
                    ))}
                </select>
            )}
        </Field>
    )
}

export const ImageField = ({ name, width, height, ...props }) => {
    const { value, setValue } = useField(name)
    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
        multiple: false,
        accept: { 'image/*': [] },
        onDrop: files => {
            if (value?.preview) {
                URL.revokeObjectURL(value.preview)
            }
            setValue(Object.assign(files[0], { preview: URL.createObjectURL(files[0]) }))
        }
    })

    React.useEffect(() => () => value && URL.revokeObjectURL(value.preview), [ value ])

    const imageSrc = fileUrl(value)

    const dropzoneProps = {
        className: classNames('dropzone', 'image-icon', 'lg', 'shadow', { accepted: isDragAccept }),
        style: { width, height }
    }
    if (imageSrc) {
        dropzoneProps.style.backgroundImage = `url(${imageSrc})`
    }

    return (
        <Field name={name} { ...props }>
            {() => (
                <div className="ImageField">
                    <div {...getRootProps(dropzoneProps)}>
                        <input {...getInputProps()} />
                        { !imageSrc && <i className="fa-solid fa-image" />}
                    </div>
                    { imageSrc && (
                        <div className="mt-3">
                            <a href="#" onClick={() => setValue(null)}>{ i18n.t('Clear Image') }</a>
                        </div>
                    )}
                </div>
            )}
        </Field>
    )
}

export const Submit = ({ children, spinnerVariant='light', ...props }) => {
    const form = useForm()
    return (
        <Button onClick={() => submitForm(form)} { ...props }>
            { children }
            { form.submitting && (
                <>&nbsp;<Spinner animation="border" size="sm" variant={spinnerVariant} /></>
            )}
        </Button>
    )
}
